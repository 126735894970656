/* eslint-disable react-hooks/exhaustive-deps */
import { Close, ExpandMore, FolderOpen } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useChannelIdContext } from "../../context/ChannelIdContext";
import { ACOption } from "../../types/Common";
import { CheckBoxControl } from "../Controls/CheckBoxControl";
import ComboBoxControl from "../Controls/ComboBoxControl";
import { TextControl } from "../Controls/TextControl";
import DeleteZoneDialog from "../Dialogs/DeleteZoneDialog/DeleteZoneDialog";
import ZoneConfigurationSideBar from "./ZoneConfigurationSideBar";
const API_URL = process.env.REACT_APP_BACKEND_URL;
let initalSetting: any = {
  id: 0,
  name: "",
  targetDBV: 0,
  agcSetting: 0,
  vstFile: null,
  zoneTitle: null,
  vstConfigFile: null,
  targetMusicdBV: null,
  fadeOnZoneStop: false,
  systemSampleRate: null,
  synchronizeSchedule: true,
  automaticGainControl: null,
  audioSystemPreffered: null,
};

export default function ZoneConfiguration() {
  const [expanded, setExpanded] = useState<{
    mqs: boolean;
    files: boolean;
    devices: boolean;
    levels: boolean;
    updating: boolean;
    locations: boolean;
  }>({
    mqs: true,
    files: true,
    devices: true,
    levels: true,
    updating: true,
    locations: true,
  });
  const { t } = useTranslation();
  const theme = useTheme();
  let navigate = useNavigate();
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const {
    deleteZoneId,
    setDeleteZoneId,
    selectedZoneId,
    selectedZoneName,
    setisDeleted,
    setIsAdded,
    setIsTokenExpired,
    setIsTokenUpdated,
    IsTokenUpdated,
  } = useChannelIdContext();
  const [appSettings, setAppSettings] = useState<any>(initalSetting);
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>("");
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [siteId, setSiteId] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [openCsvImportDialog, setOpenCsvImportDialog] =
    useState<boolean>(false);
  const [sitesList, setSitesList] = useState<any[]>([]);
  const [zoneData, setZoneData] = useState<any>({
    id: null,
    name: null,
    agcSetting: 1,
    targetDBV: 1,
    synchronizeSchedule: false,
    audioSystemPreffered: "test",
    fadeOnZoneStop: false,
    systemSampleRate: "44hz",
    automaticGainControl: "",
    targetMusicdBV: "",
    vstFile: "",
    vstConfigFile: "",
    zoneTitle: "",
    siteId: null,
    site: null,
  });
  const [isZone, setIsZone] = useState<boolean>(false);

  const GroupBySettingGroup = useCallback(async () => {
    fetch(`${API_URL}/Zone/${selectedZoneId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data.success === true) {
          setAppSettings({
            ...data?.data,
            id: selectedZoneId,
            name: selectedZoneName,
          });
        }
      });
  }, [selectedZoneId, selectedZoneName, IsTokenUpdated]);

  const handleImportFile = useCallback(() => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".mp3";
    fileInput.multiple = false;
    fileInput.addEventListener("change", (event: Event) => {
      const target = event.target as HTMLInputElement;
      const files = target.files;
      if (files && files.length > 0) {
        setAppSettings({ ...appSettings, vstFile: files[0].name });
      }
    });
    fileInput?.click();
  }, [appSettings]);

  const handleImportFileVST = useCallback(() => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".mp3";
    fileInput.multiple = false;
    fileInput.addEventListener("change", (event: Event) => {
      const target = event.target as HTMLInputElement;
      const files = target.files;
      if (files && files?.length > 0) {
        setAppSettings({ ...appSettings, vstConfigFile: files[0].name });
      }
    });
    fileInput.click();
  }, [appSettings]);

  const UpdatedSettingData = useCallback(async () => {
    try {
      fetch(`${API_URL}/Zone`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
        body: JSON.stringify(appSettings),
      })
        .then((response) => {
          if (
            response.ok === false &&
            response.status === 401 &&
            token?.refreshToken !== ""
          ) {
            setIsTokenExpired(true);
          } else if (response.status === 401 && token?.refreshToken === "") {
            navigate("/");
            localStorage.removeItem("token");
            localStorage.clear();
          } else {
            setIsTokenUpdated(false);
            return response.json();
          }
        })
        .then((data) => {
          if (data?.success) {
            toast.success(t("main.Data_Is_Updated"));
          }
        });
    } catch (e: any) {
      toast.error(e);
    }
  }, [appSettings, t]);

  // const getAllSites = useCallback(async () => {
  //   fetch(`${API_URL}/Site/GetAll`, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `bearer ${token?.accessToken}`,
  //     },
  //   })
  //     .then((response) => {
  //       if (
  //         response.ok === false &&
  //         response.status === 401 &&
  //         token?.refreshToken !== ""
  //       ) {
  //         setIsTokenExpired(true);
  //       } else if (response.status === 401 && token?.refreshToken === "") {
  //         navigate("/");
  //         localStorage.removeItem("token");
  //         localStorage.clear();
  //       } else {
  //         setIsTokenUpdated(false);
  //         return response.json();
  //       }
  //     })
  //     .then((data) => {
  //       if (data.success === true) {
  //         setSitesList(data?.data);
  //       } else {
  //         setSitesList([]);
  //       }
  //     });
  // }, [IsTokenUpdated]);

  const handleCreateZone = async (e: any) => {
    if (zoneData.name === null || siteId === null) {
      toast.error(t("main.Please_fill_the_form"));
      return;
    }
    e.preventDefault();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
      body: JSON.stringify({
        name: zoneData?.name,
        agcSetting: 1,
        targetDBV: 1,
        synchronizeSchedule: false,
        audioSystemPreffered: "",
        fadeOnZoneStop: false,
        systemSampleRate: "",
        automaticGainControl: "",
        targetMusicdBV: "",
        vstFile: "",
        vstConfigFile: "",
        zoneTitle: "",
        siteId: siteId,
      }),
    };
    fetch(`${API_URL}/Zone`, requestOptions)
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data?.success === true) {
          toast.success(t("main.Zone_Created_Successfully"));
          setZoneData({
            id: null,
            name: null,
            agcSetting: 1,
            targetDBV: 1,
            synchronizeSchedule: false,
            audioSystemPreffered: "",
            fadeOnZoneStop: false,
            systemSampleRate: "",
            automaticGainControl: "",
            targetMusicdBV: "",
            vstFile: "",
            vstConfigFile: "",
            zoneTitle: "",
            siteId: null,
            site: null,
          });
          setIsAdded(true);
          setIsZone(false);
        } else {
          toast.error(data?.message);
        }
      });
  };

  const handleConfirmationDialog = () => {
    setOpen(true);
  };

  const handleDeleteZone = async () => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    };
    fetch(`${API_URL}/Zone/${selectedZoneId}`, requestOptions)
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data?.success === true) {
          toast.success(t("main.Zone_Deleted_Successfully"));
          setisDeleted(true);
        } else {
          toast.error(data?.message);
        }
      });
  };

  useEffect(() => {
    if (confirmation) {
      handleDeleteZone();
      setConfirmation(false);
    }
  }, [confirmation]);

  const GetRegionByCustomer = useCallback(async () => {
    fetch(`${API_URL}/Zone/export-xml`, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "ZoneData.xml";
        a.click();
        toast.success(t("main.File_Is_Downloaded"));
      });
  }, [t, IsTokenUpdated]);

  useEffect(() => {
    // getAllSites();
    if (selectedZoneId) GroupBySettingGroup();
  }, [GroupBySettingGroup, selectedZoneId, selectedZoneName]);

  return (
    <div>
      <Grid className="TabbingDetails Channel" container spacing={0}>
        <Grid className="Channel_Sidebar">
          <ZoneConfigurationSideBar
            siteId={siteId}
            setSiteId={setSiteId}
            selectedCustomerId={selectedCustomerId}
            setSelectedCustomerId={setSelectedCustomerId}
            setAppSettings={setAppSettings}
          />
        </Grid>
        <Grid className="Channel_Body">
          <div className="TabbingDetails_innerBox">
            <div style={{ marginBottom: "18px", width: "100%" }}>
              <Button
                style={{ marginRight: "15px" }}
                onClick={UpdatedSettingData}
                variant="contained"
                disabled={siteId && selectedZoneId ? false : true}
              >
                {t("main.save")}
              </Button>

              <Button
                onClick={GetRegionByCustomer}
                variant="contained"
                disabled={siteId && selectedZoneId ? false : true}
              >
                {t("main.EXPORT_XML_FILE")}
              </Button>

              <Button
                onClick={() => setOpen(true)}
                variant="contained"
                style={{ marginLeft: "15px" }}
                disabled={!selectedZoneId ? true : false}
              >
                {t("main.delete_zone")}
              </Button>

              <Button
                onClick={() => setIsZone(true)}
                variant="contained"
                style={{ marginLeft: "15px" }}
                disabled={siteId && selectedCustomerId ? false : true}
              >
                {t("main.create_new_zone")}
              </Button>
              <Button
                onClick={() => setOpenCsvImportDialog(true)}
                variant="contained"
                style={{ marginLeft: "15px" }}
                disabled={siteId && selectedCustomerId ? false : true}
              >
                {t("main.import_zones")}
              </Button>
            </div>
            {selectedCustomerId && siteId ? (
              <>
                <Grid className="accordionBox" item xs={12}>
                  {" "}
                  <Accordion
                    className="AccordionTab"
                    expanded={expanded?.files}
                    onChange={(e, isExpanded) =>
                      setExpanded({ ...expanded, files: isExpanded ?? false })
                    }
                    elevation={5}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1bh-content"
                      sx={{
                        backgroundColor:
                          theme.palette.mode === "dark" ? "#151515" : "#AEAEAE",
                      }}
                    >
                      <Typography
                        variant="h5"
                        style={{
                          color:
                            theme.palette.mode === "dark" ? "#000" : "#000",
                        }}
                        sx={{
                          flexShrink: 0,
                          fontWeight: 600,
                          letterSpacing: 1,
                        }}
                      >
                        {t("main.audio_output")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="AccordionTab_details">
                      <Stack direction="column" spacing={1}>
                        <ComboBoxControl
                          header={t("main.audio_system_preferred")}
                          value={
                            appSettings?.audioSystemPreffered
                              ? appSettings?.audioSystemPreffered
                              : ""
                          }
                          inputChanged={(value: ACOption) => {
                            setAppSettings({
                              ...appSettings,
                              audioSystemPreffered: value?.label,
                            });
                          }}
                          dataSource={[
                            { id: 1, label: t("main.windows") },
                            { id: 2, label: t("main.ASIO") },
                          ]}
                        />
                        <CheckBoxControl
                          header={t("main.fade_on_zone_stop")}
                          checkText=""
                          value={
                            appSettings?.fadeOnZoneStop
                              ? appSettings?.fadeOnZoneStop
                              : false
                          }
                          inputChanged={(value: boolean) =>
                            setAppSettings({
                              ...appSettings,
                              fadeOnZoneStop: value,
                            })
                          }
                        />
                        <ComboBoxControl
                          header={t("main.system_sample_rate")}
                          value={
                            appSettings?.systemSampleRate
                              ? appSettings?.systemSampleRate
                              : ""
                          }
                          inputChanged={(value: ACOption) =>
                            setAppSettings({
                              ...appSettings,
                              systemSampleRate: value?.label,
                            })
                          }
                          dataSource={[
                            { id: 1, label: "32kHz" },
                            { id: 2, label: "44.1kHz" },
                            { id: 3, label: "48kHz" },
                            { id: 4, label: "96kHz" },
                          ]}
                        />
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid className="accordionBox" item xs={12}>
                  <Accordion
                    className="AccordionTab"
                    expanded={expanded?.devices}
                    onChange={(e, isExpanded) =>
                      setExpanded({ ...expanded, devices: isExpanded ?? false })
                    }
                    elevation={5}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1bh-content"
                      style={{
                        backgroundColor:
                          theme.palette.mode === "dark" ? "#151515" : "#AEAEAE",
                      }}
                    >
                      <Typography
                        variant="h5"
                        style={{
                          color:
                            theme.palette.mode === "dark" ? "#000" : "#000",
                        }}
                        sx={{
                          flexShrink: 0,
                          fontWeight: 600,
                          letterSpacing: 1,
                        }}
                      >
                        {t("main.audio_processing")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="AccordionTab_details">
                      <Stack direction="column" spacing={1}>
                        <ComboBoxControl
                          header={"Soft Kompressor"}
                          value={
                            appSettings?.automaticGainControl
                              ? appSettings?.automaticGainControl
                              : ""
                          }
                          inputChanged={(value: ACOption) =>
                            setAppSettings({
                              ...appSettings,
                              automaticGainControl: value?.label,
                            })
                          }
                          dataSource={[
                            { id: 1, label: t("main.soft") },
                            { id: 2, label: t("main.medium") },
                            { id: 3, label: t("main.hard") },
                          ]}
                        />
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            md={5}
                            container
                            direction="column"
                            alignItems="start"
                            justifyContent="center"
                          >
                            <Typography
                              className={
                                theme.palette.mode === "dark"
                                  ? "BoxLabel-dark"
                                  : "BoxLabel"
                              }
                            >
                              {t("main.target_music_dBV")}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={7}
                            container
                            direction="column"
                            justifyContent="center"
                          >
                            <div className="CustomNumber">
                              <TextControl
                                header="Target Music dBV"
                                value={
                                  appSettings?.targetMusicdBV
                                    ? appSettings?.targetMusicdBV
                                    : ""
                                }
                                inputChanged={(value: string) =>
                                  setAppSettings({
                                    ...appSettings,
                                    targetMusicdBV: value,
                                  })
                                }
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid className="accordionBox" item xs={12}>
                  <Accordion
                    className="AccordionTab"
                    expanded={expanded?.levels}
                    onChange={(e, isExpanded) =>
                      setExpanded({ ...expanded, levels: isExpanded ?? false })
                    }
                    elevation={5}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1bh-content"
                      sx={{
                        backgroundColor:
                          theme.palette.mode === "dark" ? "#151515" : "#AEAEAE",
                      }}
                    >
                      <Typography
                        variant="h5"
                        style={{
                          color:
                            theme.palette.mode === "dark" ? "#000" : "#000",
                        }}
                        sx={{
                          flexShrink: 0,
                          fontWeight: 600,
                          letterSpacing: 1,
                        }}
                      >
                        {t("main.External_Effect")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="AccordionTab_details">
                      <Stack direction="column" spacing={1}>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            md={5}
                            container
                            direction="column"
                            alignItems="start"
                            justifyContent="center"
                          >
                            <Typography
                              className={
                                theme.palette.mode === "dark"
                                  ? "BoxLabel-dark"
                                  : "BoxLabel"
                              }
                            >
                              {t("main.VST_File")}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={7}
                            container
                            direction="column"
                            justifyContent="center"
                          >
                            <div className="CustomFormControl UploadFile">
                              <div className="CustomNumber">
                                <TextControl
                                  header="Zone Title"
                                  value={
                                    appSettings?.vstFile
                                      ? appSettings?.vstFile
                                      : ""
                                  }
                                  inputChanged={(value: string) =>
                                    setAppSettings({
                                      ...appSettings,
                                      vstFile: value,
                                    })
                                  }
                                />
                              </div>
                              <Button
                                className="uploadBtn"
                                variant="contained"
                                component="span"
                                onClick={handleImportFile}
                              >
                                <FolderOpen />
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            md={5}
                            container
                            direction="column"
                            alignItems="start"
                            justifyContent="center"
                          >
                            <Typography
                              className={
                                theme.palette.mode === "dark"
                                  ? "BoxLabel-dark"
                                  : "BoxLabel"
                              }
                            >
                              {t("main.VST_config_file")}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={7}
                            container
                            direction="column"
                            justifyContent="center"
                          >
                            <div className="CustomFormControl UploadFile">
                              <div className="CustomNumber">
                                <TextControl
                                  header="Zone Title"
                                  value={
                                    appSettings?.vstConfigFile
                                      ? appSettings?.vstConfigFile
                                      : ""
                                  }
                                  inputChanged={(value: string) =>
                                    setAppSettings({
                                      ...appSettings,
                                      vstConfigFile: value,
                                    })
                                  }
                                />
                              </div>
                              <Button
                                className="uploadBtn"
                                variant="contained"
                                component="span"
                                onClick={handleImportFileVST}
                              >
                                <FolderOpen />
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid className="accordionBox" item xs={12}>
                  <Accordion
                    className="AccordionTab"
                    expanded={expanded?.locations}
                    onChange={(e, isExpanded) =>
                      setExpanded({
                        ...expanded,
                        locations: isExpanded ?? false,
                      })
                    }
                    elevation={5}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1bh-content"
                      sx={{
                        backgroundColor:
                          theme.palette.mode === "dark" ? "#151515" : "#AEAEAE",
                      }}
                    >
                      <Typography
                        variant="h5"
                        style={{
                          color:
                            theme.palette.mode === "dark" ? "#000" : "#000",
                        }}
                        sx={{
                          flexShrink: 0,
                          fontWeight: 600,
                          letterSpacing: 1,
                        }}
                      >
                        {t("main.general")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="AccordionTab_details">
                      <Stack>
                        <CheckBoxControl
                          header={t("main.synchronize_schedule")}
                          checkText=""
                          value={
                            appSettings?.syncronizedSchedule
                              ? appSettings?.syncronizedSchedule
                              : false
                          }
                          inputChanged={(value: boolean) =>
                            setAppSettings({
                              ...appSettings,
                              syncronizedSchedule: value,
                            })
                          }
                        />
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid className="accordionBox" item xs={12}>
                  <Accordion
                    className="AccordionTab"
                    expanded={expanded?.mqs}
                    onChange={(e, isExpanded) =>
                      setExpanded({ ...expanded, mqs: isExpanded ?? false })
                    }
                    elevation={5}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1bh-content"
                      sx={{
                        backgroundColor:
                          theme.palette.mode === "dark" ? "#151515" : "#AEAEAE",
                      }}
                    >
                      <Typography
                        variant="h5"
                        style={{
                          color:
                            theme.palette.mode === "dark" ? "#000" : "#000",
                        }}
                        sx={{
                          flexShrink: 0,
                          fontWeight: 600,
                          letterSpacing: 1,
                        }}
                      >
                        {t("main.zone_config")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="AccordionTab_details">
                      <Stack>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            md={5}
                            container
                            direction="column"
                            alignItems="start"
                            justifyContent="center"
                          >
                            <Typography
                              className={
                                theme.palette.mode === "dark"
                                  ? "BoxLabel-dark"
                                  : "BoxLabel"
                              }
                            >
                              {t("main.zone_title")}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={7}
                            container
                            direction="column"
                            justifyContent="center"
                          >
                            <div className="CustomNumber">
                              <TextControl
                                header="Zone Title"
                                value={
                                  appSettings?.zoneTitle
                                    ? appSettings?.zoneTitle
                                    : ""
                                }
                                inputChanged={(value: string) =>
                                  setAppSettings({
                                    ...appSettings,
                                    zoneTitle: value,
                                  })
                                }
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </>
            ) : (
              <Grid
                className="horizontalTabs__item"
                sx={{
                  marginTop: 4,
                  width: "100%",
                  border: "1px solid #9E9E9E",
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#202020" : "#d9d9d9",
                }}
              >
                {t("main.please_select_customer_and_site_to_get_zone_data")}
              </Grid>
            )}
          </div>
          {
            <>
              <Dialog
                open={isZone}
                onClose={() => {
                  setIsZone(false);
                  setZoneData({
                    id: null,
                    name: null,
                    agcSetting: 1,
                    targetDBV: 1,
                    synchronizeSchedule: true,
                    audioSystemPreffered: "test",
                    fadeOnZoneStop: true,
                    systemSampleRate: "44hz",
                    automaticGainControl: "",
                    targetMusicdBV: "",
                    vstFile: "",
                    vstConfigFile: "",
                    zoneTitle: "",
                    siteId: null,
                    site: null,
                  });
                }}
                aria-labelledby="draggable-dialog-title"
                maxWidth="sm"
                fullWidth
                className="duplicateZoneDialog"
              >
                <div className="duplicateZoneDialog__inner">
                  <Typography
                    variant="h5"
                    className="duplicateZoneDialog-title"
                  >
                    {t("main.create_new_zone")}
                    <Link
                      className="modelCloseBtn"
                      to={""}
                      onClick={() => {
                        setIsZone(false);
                        setZoneData({
                          id: null,
                          name: null,
                          agcSetting: 1,
                          targetDBV: 1,
                          synchronizeSchedule: false,
                          audioSystemPreffered: "",
                          fadeOnZoneStop: false,
                          systemSampleRate: "",
                          automaticGainControl: "",
                          targetMusicdBV: "",
                          vstFile: "",
                          vstConfigFile: "",
                          zoneTitle: "",
                          siteId: null,
                          site: null,
                        });
                      }}
                    >
                      <Close />
                    </Link>
                  </Typography>
                  <DialogContent>
                    <Box className="fieldBox">
                      <Box className="fieldBox__head">
                        <Typography
                          variant="body1"
                          className="fieldBox__head-title"
                        >
                          {t("main.zone_name/title")}
                        </Typography>
                      </Box>
                      <Box className="fieldBox__body">
                        <input
                          value={zoneData?.name}
                          onChange={(e) =>
                            setZoneData({ ...zoneData, name: e.target.value })
                          }
                          style={{
                            padding: "inherit",
                            border: "1px solid white",
                            borderRadius: "50px",
                            width: "315px",
                            fontSize: "15px",
                            color: "white",
                            background: "transparent",
                            height: "30px",
                          }}
                        />
                      </Box>
                    </Box>
                    {/* <Box className="fieldBox">
                      <Box className="fieldBox__head">
                        <Typography className="fieldBox__head-title">
                          {t("main.select_site")}
                        </Typography>
                      </Box>
                      <Box className="fieldBox__body">
                        <Select
                          style={{
                            height: 35,
                            width: 300,
                            borderRadius: 10,
                          }}
                          displayEmpty
                          placeholder="Sites"
                          value={zoneData?.siteId}
                          renderValue={(value) => {
                            if (value) {
                              return sitesList?.find(
                                (item: any) =>
                                  item?.id === parseInt(zoneData?.siteId)
                              )?.name;
                            } else {
                              return <Typography>Site</Typography>;
                            }
                          }}
                          onChange={(event: any) =>
                            setZoneData({
                              ...zoneData,
                              siteId: event.target.value,
                            })
                          }
                        >
                          {sitesList?.length === 0 ? (
                            <MenuItem key="customer">
                              {t("main.please_select_customer")}
                            </MenuItem>
                          ) : (
                            sitesList?.map((item: any) => (
                              <MenuItem key={item?.id} value={item?.id}>
                                {item?.name}
                              </MenuItem>
                            ))
                          )}
                        </Select>
                      </Box>
                    </Box> */}
                  </DialogContent>
                  <DialogActions sx={{ display: "flex" }}>
                    <Button
                      size="medium"
                      variant="contained"
                      color="primary"
                      sx={{ mt: 5 }}
                      style={{ backgroundColor: "#3C735C", border: "none" }}
                      onClick={handleCreateZone}
                    >
                      {t("main.save")}
                    </Button>
                    <Button
                      size="medium"
                      variant="outlined"
                      color="primary"
                      sx={{ mt: 5 }}
                      onClick={() => {
                        setZoneData({
                          id: null,
                          name: null,
                          agcSetting: 1,
                          targetDBV: 1,
                          synchronizeSchedule: true,
                          audioSystemPreffered: "test",
                          fadeOnZoneStop: true,
                          systemSampleRate: "44hz",
                          automaticGainControl: "",
                          targetMusicdBV: "",
                          vstFile: "",
                          vstConfigFile: "",
                          zoneTitle: "",
                          siteId: null,
                          site: null,
                        });
                        setIsZone(false);
                      }}
                    >
                      {t("main.cancel")}
                    </Button>
                  </DialogActions>
                </div>
              </Dialog>
            </>
          }
        </Grid>
      </Grid>
      <DeleteZoneDialog
        isOpen={open}
        handleClose={() => setOpen(false)}
        setConfirmation={setConfirmation}
      ></DeleteZoneDialog>
    </div>
  );
}
